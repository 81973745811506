import { Experiment } from "redmond";
import {
  AVAILABLE,
  CONTROL,
  LC_GATING_EXPERIMENT,
  PREMIER_COLLECTION_EXPERIMENT,
  boostPremierCollectionCustomSortExperiment,
  boostPremierCollectionExperiment,
  getExperimentDefVariant,
  getExperimentVariant,
} from "../../../../context/experiments";
import { mapExperimentVariants } from "../../../../context/experiment-def";

/**
 * We show the Premium stays filter if there are more lodgings than what is shown in the top of the list,
 * and if the Premium stays is enabled with the proper boost experiment.
 */
export function shouldShowPremiumStaysFilter(
  collectionSpecificLodgingCounts: {
    all: number;
    top: number;
  },
  experiments: Array<Experiment>
): boolean {
  const isPremierCollectionEnabled =
    getExperimentVariant(experiments, PREMIER_COLLECTION_EXPERIMENT) ===
    AVAILABLE;
  const isLegacyBoostForPremierCollectionEnabled = mapExperimentVariants(
    boostPremierCollectionExperiment,
    (variant) => {
      switch (variant) {
        case "control":
        case "available":
          return false;
        case "2-boost":
        case "2-boost-randomized":
        case "3-boost":
        case "3-boost-randomized":
          return true;
      }
    }
  );
  const isBoostForPremierCollectionEnabled =
    isLegacyBoostForPremierCollectionEnabled ||
    getExperimentDefVariant(
      experiments,
      boostPremierCollectionCustomSortExperiment
    ) !== CONTROL;

  return (
    isPremierCollectionEnabled &&
    isBoostForPremierCollectionEnabled &&
    collectionSpecificLodgingCounts.all > collectionSpecificLodgingCounts.top
  );
}

/**
 * We show the Lifestyle Collection filter if there are more lodgings than what is shown in the top of the list,
 * and if the Lifestyle Collection is enabled with the proper boost experiment.
 * Don't show the filter if the Premium Collection is enabled.
 */
export function shouldShowLifestyleStaysFilter(
  collectionSpecificLodgingCounts: {
    all: number;
    top: number;
  },
  experiments: Array<Experiment>
): boolean {
  const isPremierCollectionEnabled =
    getExperimentVariant(experiments, PREMIER_COLLECTION_EXPERIMENT) ===
    AVAILABLE;
  const isLifestyleCollectionEnabled =
    getExperimentVariant(experiments, LC_GATING_EXPERIMENT) === AVAILABLE;
  const isBoostForLifestyleCollectionEnabled =
    getExperimentDefVariant(
      experiments,
      boostPremierCollectionCustomSortExperiment
    ) !== CONTROL;

  return (
    !isPremierCollectionEnabled &&
    isLifestyleCollectionEnabled &&
    isBoostForLifestyleCollectionEnabled &&
    collectionSpecificLodgingCounts.all > collectionSpecificLodgingCounts.top
  );
}
