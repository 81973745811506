export const FETCH_INITIAL_HOTEL_AVAILABILITY =
  "hotelAvailability/FETCH_INITIAL_HOTEL_AVAILABILITY";
export type FETCH_INITIAL_HOTEL_AVAILABILITY =
  typeof FETCH_INITIAL_HOTEL_AVAILABILITY;

export const FETCH_MORE_HOTEL_AVAILABILITY =
  "hotelAvailability/FETCH_MORE_HOTEL_AVAILABILITY";
export type FETCH_MORE_HOTEL_AVAILABILITY =
  typeof FETCH_MORE_HOTEL_AVAILABILITY;

export const SET_HOTEL_AVAILABILITY_RESULTS =
  "hotelAvailability/SET_HOTEL_AVAILABILITY_RESULTS";
export type SET_HOTEL_AVAILABILITY_RESULTS =
  typeof SET_HOTEL_AVAILABILITY_RESULTS;

export const SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED =
  "hotelAvailability/SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED";
export type SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED;

export const SET_LODGING_ID_IN_FOCUS =
  "hotelAvailability/SET_LODGING_ID_IN_FOCUS";
export type SET_LODGING_ID_IN_FOCUS = typeof SET_LODGING_ID_IN_FOCUS;

export const SET_LODGING_ID_HOVERED =
  "hotelAvailability/SET_LODGING_ID_HOVERED";
export type SET_LODGING_ID_HOVERED = typeof SET_LODGING_ID_HOVERED;

export const SET_AMENITIES_FILTER = "hotelAvailability/SET_AMENITIES_FILTER";
export type SET_AMENITIES_FILTER = typeof SET_AMENITIES_FILTER;

export const SET_STAR_RATINGS_FILTER =
  "hotelAvailability/SET_STAR_RATINGS_FILTER";
export type SET_STAR_RATINGS_FILTER = typeof SET_STAR_RATINGS_FILTER;

export const SET_MAX_PRICE_FILTER = "hotelAvailability/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_HOTEL_NAME_FILTER = "hotelAvailability/SET_HOTEL_NAME_FILTER";
export type SET_HOTEL_NAME_FILTER = typeof SET_HOTEL_NAME_FILTER;

export const SET_PREMIER_COLLECTION_FILTER =
  "hotelAvailability/SET_PREMIER_COLLECTION_FILTER";
export type SET_PREMIER_COLLECTION_FILTER =
  typeof SET_PREMIER_COLLECTION_FILTER;

export const SET_LIFESTYLE_COLLECTION_FILTER =
  "hotelAvailability/SET_LIFESTYLE_COLLECTION_FILTER";
export type SET_LIFESTYLE_COLLECTION_FILTER =
  typeof SET_LIFESTYLE_COLLECTION_FILTER;

export const SET_FREE_CANCEL_FILTER =
  "hotelAvailability/SET_FREE_CANCEL_FILTER";
export type SET_FREE_CANCEL_FILTER = typeof SET_FREE_CANCEL_FILTER;

export const SET_HOTELS_ON_SALE_FILTER =
  "hotelAvailability/SET_HOTELS_ON_SALE_FILTER";
export type SET_HOTELS_ON_SALE_FILTER = typeof SET_HOTELS_ON_SALE_FILTER;

export const SET_HOTEL_SORT_OPTION = "hotelAvailability/SET_HOTEL_SORT_OPTION";
export type SET_HOTEL_SORT_OPTION = typeof SET_HOTEL_SORT_OPTION;

export const SET_MAP_BOUND = "hotelAvailability/SET_MAP_BOUND";
export type SET_MAP_BOUND = typeof SET_MAP_BOUND;

export const SET_SEARCH_LODGING_IDS =
  "hotelAvailability/SET_SEARCH_LODGING_IDS";
export type SET_SEARCH_LODGING_IDS = typeof SET_SEARCH_LODGING_IDS;

export const SET_OPEN_DATES_MODAL = "hotelAvailability/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_SEARCHED_DATES = "hotelAvailability/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_LOCATION_RESULT =
  "hotelAvailability/SET_SEARCHED_LOCATION_RESULT";
export type SET_SEARCHED_LOCATION_RESULT = typeof SET_SEARCHED_LOCATION_RESULT;

export const SET_HAS_VIEWED_UNAVAILABLE_HOTEL =
  "hotelAvailability/SET_HAS_VIEWED_UNAVAILABLE_HOTEL";
export type SET_HAS_VIEWED_UNAVAILABLE_HOTEL =
  typeof SET_HAS_VIEWED_UNAVAILABLE_HOTEL;

export const SET_SELECTED_LODGING_INDEX =
  "hotelAvailability/SET_SELECTED_LODGING_INDEX";
export type SET_SELECTED_LODGING_INDEX = typeof SET_SELECTED_LODGING_INDEX;

export const SET_SEARCHED_OCCUPANCY_COUNTS =
  "hotelAvailability/SET_SEARCHED_OCCUPANCY_COUNTS";
export type SET_SEARCHED_OCCUPANCY_COUNTS =
  typeof SET_SEARCHED_OCCUPANCY_COUNTS;

export const SET_SEARCHED_ROOMS_COUNT =
  "hotelAvailability/SET_SEARCHED_ROOMS_COUNT";
export type SET_SEARCHED_ROOMS_COUNT = typeof SET_SEARCHED_ROOMS_COUNT;

export const SET_SEARCHED_PETS_COUNT =
  "hotelAvailability/SET_SEARCHED_PETS_COUNT";
export type SET_SEARCHED_PETS_COUNT = typeof SET_SEARCHED_PETS_COUNT;

export const SET_IS_HOTEL_FILTER_EXPERIMENT =
  "hotelAvailability/SET_IS_HOTEL_FILTER_EXPERIMENT";
export type SET_IS_HOTEL_FILTER_EXPERIMENT =
  typeof SET_IS_HOTEL_FILTER_EXPERIMENT;

export const SET_HOTEL_AVAIL_ENTRY_POINT =
  "hotelAvailability/SET_HOTEL_AVAIL_ENTRY_POINT";
export type SET_HOTEL_AVAIL_ENTRY_POINT = typeof SET_HOTEL_AVAIL_ENTRY_POINT;

export const FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  "hotelShop/FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES";
export type FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  typeof FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;

export const SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  "hotelShop/SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES";
export type SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  typeof SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;

export const SET_VIEW_HOTELS_NEAR_LOCATION =
  "hotelShop/SET_VIEW_HOTELS_NEAR_LOCATION";
export type SET_VIEW_HOTELS_NEAR_LOCATION =
  typeof SET_VIEW_HOTELS_NEAR_LOCATION;

export const SET_POLICY_FILTER = "hotelAvailability/SET_POLICY_FILTER";
export type SET_POLICY_FILTER = typeof SET_POLICY_FILTER;

export const SET_LOYALTY_PROGRAMS_FILTER =
  "hotelAvailability/SET_LOYALTY_PROGRAMS_FILTER";
export type SET_LOYALTY_PROGRAMS_FILTER = typeof SET_LOYALTY_PROGRAMS_FILTER;

export const FETCH_USER_HOTEL_PREFERENCES =
  "customerProfilePreferences/FETCH_USER_HOTEL_PREFERENCES";
export type FETCH_USER_HOTEL_PREFERENCES = typeof FETCH_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES =
  "customerProfilePreferences/SET_USER_HOTEL_PREFERENCES";
export type SET_USER_HOTEL_PREFERENCES = typeof SET_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED";
export type SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;

export const SET_IS_PREMIER_COLLECTION_ENABLED =
  "hotelAvailability/SET_IS_PREMIER_COLLECTION_ENABLED";
export type SET_IS_PREMIER_COLLECTION_ENABLED =
  typeof SET_IS_PREMIER_COLLECTION_ENABLED;

export const SET_PREMIUM_STAYS_FILTER =
  "hotelAvailability/SET_PREMIUM_STAYS_FILTER";
export type SET_PREMIUM_STAYS_FILTER = typeof SET_PREMIUM_STAYS_FILTER;

export const SET_IS_LIFESTYLE_COLLECTION_ENABLED =
  "hotelAvailability/SET_IS_LIFESTYLE_COLLECTION_ENABLED";
export type SET_IS_LIFESTYLE_COLLECTION_ENABLED =
  typeof SET_IS_LIFESTYLE_COLLECTION_ENABLED;

export const SET_LIFESTYLE_STAYS_FILTER =
  "hotelAvailability/SET_LIFESTYLE_STAYS_FILTER";
export type SET_LIFESTYLE_STAYS_FILTER = typeof SET_LIFESTYLE_STAYS_FILTER;

export const SET_STAY_TYPES_FILTER = "hotelAvailability/SET_STAY_TYPES_FILTER";
export type SET_STAY_TYPES_FILTER = typeof SET_STAY_TYPES_FILTER;

export const SET_MEAL_PLAN_TYPES_FILTER =
  "hotelAvailability/SET_MEAL_PLAN_TYPES_FILTER";
export type SET_MEAL_PLAN_TYPES_FILTER = typeof SET_MEAL_PLAN_TYPES_FILTER;

export const RESET_HOTEL_AVAILABILITY_CALL_STATE =
  "hotelAvailability/RESET_HOTEL_AVAILABILITY_CALL_STATE";
export type RESET_HOTEL_AVAILABILITY_CALL_STATE =
  typeof RESET_HOTEL_AVAILABILITY_CALL_STATE;

export const LIST_PAYMENT_METHODS = "hotelAvailability/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS = "hotelAvailability/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "hotelAvailability/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;
