import dayjs from "dayjs";
import { isEqual } from "lodash-es";
import {
  HotelEntryProperties,
  IIdLodgings,
  IPriceRange,
  IResult,
  ITrackingProperties,
  Lodging,
  LodgingCollectionEnum,
  Place,
  StatementCreditDetail,
  TravelWalletOffer,
  ViewedHotelListProperties,
  ViewHotelsNearAvailabilityProperties,
} from "redmond";
import { MealPlanKindEnum, StayType } from "redmond/hotels-module/interfaces";
import { createSelector } from "reselect";
import { IStoreState } from "../../../../reducers/types";
import {
  getSelectedAccount,
  getSelectedAccountIndex,
} from "../../../rewards/reducer";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
} from "../../../rewards/reducer/selectors";
import {
  getAdultsCount,
  getApplyUserHotelPreferences,
  getChildren,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import {
  getActiveCrossSellOffers,
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";
import { initialFilterState } from "../index";
import {
  HotelAvailabilityCallState,
  HotelAvailabilitySortOption,
} from "../state";
import {
  performAmenitiesFilter,
  performFreeCancelFilter,
  performHotelNameFilter,
  performHotelsOnSaleFilter,
  performLifestyleCollectionFilter,
  performLoyaltyProgramFilter,
  performMaxPriceFilter,
  performMealPlanTypeFilter,
  performPolicyFilter,
  performPremierAndLifestyleCollectionFilter,
  performStarRatingsFilter,
  performStayTypeFilter,
} from "../utils/processFilters";
import {
  orderByPriceLowToHigh,
  orderByStarRatingHighToLow,
} from "../utils/processSort";

export const getHotelAvailEntryPoint = (state: IStoreState) =>
  state.hotelAvailability.hotelAvailEntryPoint;

export const getHotelAvailabilityTrackingProperties = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.trackingPropertiesV2;

export const getHotelAvailabilityLodgings = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.lodgings;

export const getHotelAvailabilityNextPageToken = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.nextPageToken;

export const getHotelAvailabilityCallState = (state: IStoreState) =>
  state.hotelAvailability.hotelAvailabilityCallState;

export const getHotelAvailabilitySearchLocation = (state: IStoreState) =>
  state.hotelAvailability.searchLocation;

export const getHotelAvailabilityLodgingIdInFocus = (state: IStoreState) =>
  state.hotelAvailability.lodgingIdInFocus;

export const getPaymentMethods = (state: IStoreState) =>
  state.hotelAvailability.paymentMethods;

export const getHotelAvailabilityLodgingIdHovered = (state: IStoreState) =>
  state.hotelAvailability.lodgingIdHovered;

export const getHotelAvailabilityAmenitiesFilter = (state: IStoreState) =>
  state.hotelAvailability.amenities;

export const getHotelAvailabilityFiltersBannerMessage = (state: IStoreState) =>
  state.hotelAvailability.filtersBannerMessage;

export const getHotelAvailabilityStarRatingsFilter = (state: IStoreState) =>
  state.hotelAvailability.starRatings;

export const getHotelAvailabilityPolicyFilter = (state: IStoreState) =>
  state.hotelAvailability.isInPolicy;

export const getHotelAvailabilityLoyaltyProgramsFilter = (state: IStoreState) =>
  state.hotelAvailability.loyaltyPrograms;

export const getHotelAvailabilityMaxPriceFilter = (state: IStoreState) =>
  state.hotelAvailability.maxPrice;

export const getHotelAvailabilityFreeCancelFilter = (state: IStoreState) =>
  state.hotelAvailability.freeCancel;

export const getHotelAvailabilityHotelsOnSaleFilter = (state: IStoreState) =>
  state.hotelAvailability.hotelsOnSaleOnly;

export const getHotelAvailabilityStayTypeFilter = (state: IStoreState) =>
  state.hotelAvailability.stayTypes;

export const getHotelAvailabilityMealPlanTypeFilter = (state: IStoreState) =>
  state.hotelAvailability.mealPlanTypes;

export const getSelectedLodgingIndex = (state: IStoreState) =>
  state.hotelAvailability.selectedLodgingIndex;

export const getHotelBestOfferOverall = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.bestOverallOffer;

export const getApplicableHotelOffer = createSelector(
  getHotelBestOfferOverall,
  getActiveCrossSellOffers,
  (
    hotelBestOfferOverall,
    activeCrossSellOffers
  ): TravelWalletOffer | undefined => {
    return hotelBestOfferOverall
      ? hotelBestOfferOverall
      : activeCrossSellOffers.length > 0
      ? activeCrossSellOffers[0]
      : undefined;
  }
);
export const getUserHotelPreferences = (state: IStoreState) =>
  state.hotelAvailability.userHotelPreferences;

export const getUserHotelPreferencesCallState = (state: IStoreState) =>
  state.hotelAvailability.userHotelPreferencesCallState;

export const getHasUserSetHotelPreferences = createSelector(
  getUserHotelPreferences,
  (userHotelPreferences): boolean => {
    return (
      !!userHotelPreferences?.amenities.length ||
      !!userHotelPreferences?.starRatings.length ||
      !!userHotelPreferences?.freeCancellationOnly
    );
  }
);

export const getIsPremierCollectionEnabled = (state: IStoreState) =>
  state.hotelAvailability.isPremierCollectionEnabled;

export const getIsLifestyleCollectionEnabled = (state: IStoreState) =>
  state.hotelAvailability.isLifestyleCollectionEnabled;

/**
 * Returns the deduplicated lodgings, with the following rules:
 * - If there's no lodgings, return the lodgings as is
 * - If the premier collection is not enabled, filter out premier collection
 * - If premier collection is enabled and there's duplicated lodgings id, deduplicate by keeping only the best collection available (Premier > Lifestyle > No Collection)
 */
export const getDeduplicatedLodgings = createSelector(
  getHotelAvailabilityLodgings,
  getIsPremierCollectionEnabled,
  getIsLifestyleCollectionEnabled,
  (lodgings, isPremierCollectionEnabled, isLifestyleCollectionEnabled) => {
    let filteredLodgings = [];
    if (lodgings?.length) {
      if (isPremierCollectionEnabled) {
        filteredLodgings = lodgings.reduce((acc, curr) => {
          const existingHotelIndex = acc.findIndex(
            (l) => l.lodging.id === curr.lodging.id
          );
          if (existingHotelIndex > -1) {
            const existingHotel = acc[existingHotelIndex];
            if (
              existingHotel.lodgingCollection === LodgingCollectionEnum.Premier
            ) {
              // Nothing to do, we already have the best collection available
            } else if (
              curr.lodgingCollection === LodgingCollectionEnum.Premier ||
              (existingHotel.lodgingCollection ===
                LodgingCollectionEnum.NoCollection &&
                curr.lodgingCollection === LodgingCollectionEnum.Lifestyle)
            ) {
              // We found a better collection, let's replace it
              acc[existingHotelIndex] = curr;
            } else {
              // Nothing to do, we havean equivalent or worse collection
            }
            return acc;
          }
          acc.push(curr);
          return acc;
        }, [] as Lodging[]);
      } else if (isLifestyleCollectionEnabled) {
        filteredLodgings = lodgings.reduce((acc, curr) => {
          const existingHotelIndex = acc.findIndex(
            (l) => l.lodging.id === curr.lodging.id
          );
          if (existingHotelIndex > -1) {
            const existingHotel = acc[existingHotelIndex];
            if (
              existingHotel.lodgingCollection ===
              LodgingCollectionEnum.Lifestyle
            ) {
              // Nothing to do, we already have the best collection available
            } else if (
              curr.lodgingCollection === LodgingCollectionEnum.Lifestyle
            ) {
              // We found a better collection, let's replace it
              acc[existingHotelIndex] = curr;
            } else {
              // Nothing to do, we havean equivalent or worse collection
            }
            return acc;
          }
          if (curr.lodgingCollection !== LodgingCollectionEnum.Premier) {
            acc.push(curr);
          }
          return acc;
        }, [] as Lodging[]);
      } else {
        // filter out premier collection versions
        filteredLodgings = lodgings.filter(
          (lodging) =>
            lodging.lodgingCollection !== LodgingCollectionEnum.Premier
        );
      }
      return filteredLodgings;
    }

    return [];
  }
);
export const getHotelAvailabilityMinMaxPriceRange = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): IPriceRange | null => {
    if (!lodgings || lodgings.length === 0) {
      return null;
    }

    let curMin = lodgings[0].price?.nightlyPrice.fiat.value;
    let curMax = lodgings[0].price?.nightlyPrice.fiat.value;

    lodgings.forEach((lodging) => {
      if (lodging.price) {
        const curPrice = lodging.price.nightlyPrice.fiat.value;

        if (!curMin || curPrice < curMin) curMin = curPrice;
        if (!curMax || curPrice > curMax) curMax = curPrice;
      }
    });

    // price happens to be optional, so there could be a case where all lodgings have no price
    if (curMin === undefined || curMax === undefined) return null;

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const getHotelAvailabilityHotelNameFilter = (state: IStoreState) =>
  state.hotelAvailability.hotelName;

export const hasChangedHotelAvailabilityAmenitiesFilter = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  (amenities) => {
    return !isEqual(amenities, initialFilterState.amenities);
  }
);

export const hasChangedHotelAvailabilityStarRatingsFilter = createSelector(
  getHotelAvailabilityStarRatingsFilter,
  (starRatings) => {
    return !isEqual(starRatings, initialFilterState.starRatings);
  }
);

export const hasChangedHotelAvailabilityStayTypeFilter = createSelector(
  getHotelAvailabilityStayTypeFilter,
  (stayTypes) => {
    return !isEqual(stayTypes, initialFilterState.stayTypes);
  }
);

export const hasChangedHotelAvailabilityMealPlanTypeFilter = createSelector(
  getHotelAvailabilityMealPlanTypeFilter,
  (mealPlanTypes) => {
    return !isEqual(mealPlanTypes, initialFilterState.mealPlanTypes);
  }
);

export const hasChangedHotelAvailabilityHotelNameFilter = createSelector(
  getHotelAvailabilityHotelNameFilter,
  (hotelName) => {
    return !isEqual(hotelName, initialFilterState.hotelName);
  }
);

export const hasChangedHotelAvailabilityFreeCancelFilter = createSelector(
  getHotelAvailabilityFreeCancelFilter,
  (freeCancel) => freeCancel !== initialFilterState.freeCancel
);

export const hasChangedHotelAvailabilityHotelsOnSaleFilter = createSelector(
  getHotelAvailabilityHotelsOnSaleFilter,
  (hotelsOnSaleOnly) => hotelsOnSaleOnly !== initialFilterState.hotelsOnSaleOnly
);

export const hasChangedHotelAvailabilityMaxPriceFilter = createSelector(
  getHotelAvailabilityMaxPriceFilter,
  (maxPrice) => {
    return maxPrice !== initialFilterState.maxPrice;
  }
);

export const hasChangedHotelAvailabilityPolicyFilter = createSelector(
  getHotelAvailabilityPolicyFilter,
  (isInPolicy) => isInPolicy !== initialFilterState.isInPolicy
);

export const getAppliedHotelAvailabilityFilterCount = createSelector(
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityFreeCancelFilter,
  hasChangedHotelAvailabilityHotelNameFilter,
  hasChangedHotelAvailabilityHotelsOnSaleFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  hasChangedHotelAvailabilityStayTypeFilter,
  hasChangedHotelAvailabilityMealPlanTypeFilter,
  (
    hasChangedAmenitiesFilter,
    hasChangedFreeCancelFilter,
    hasChangedHotelNameFilter,
    hasChangedHotelsOnSaleFilter,
    hasChangedMaxPriceFilter,
    hasChangedStarRatingsFilter,
    hasChangedStayTypeFilter,
    hasChangedMealPlanTypeFilter
  ) =>
    [
      hasChangedAmenitiesFilter,
      hasChangedFreeCancelFilter,
      hasChangedHotelNameFilter,
      hasChangedHotelsOnSaleFilter,
      hasChangedStarRatingsFilter,
      hasChangedMaxPriceFilter,
      hasChangedStayTypeFilter,
      hasChangedMealPlanTypeFilter,
    ].filter((applied) => applied).length
);

export const getHotelAvailabilityCurrency = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): string => {
    if (lodgings) {
      const lodgingWithPrice = lodgings.find(
        (lodging) => !!lodging.price?.nightlyPrice
      );

      if (lodgingWithPrice?.price) {
        return lodgingWithPrice.price.nightlyPrice.fiat.currencyCode;
      }
    }

    return "USD";
  }
);

export const getShowPremiumStaysOnlyFilter = (state: IStoreState) =>
  state.hotelAvailability.showPremiumStaysOnly;

export const getShowLifestyleStaysOnlyFilter = (state: IStoreState) =>
  state.hotelAvailability.showLifestyleStaysOnly;

export const getHotelAvailabilityLodgingsHasHomesAndHotels = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): boolean => {
    if (lodgings) {
      return (
        lodgings.filter((lodging) => lodging.stayType == StayType.Homes)
          .length > 0 &&
        lodgings.filter((lodging) => lodging.stayType == StayType.Hotels)
          .length > 0
      );
    }
    return false;
  }
);

export const getHotelAvailabilityLodgingsHasMealplans = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): boolean => {
    if (lodgings) {
      return lodgings?.some(
        (lodging) =>
          lodging.price?.mealPlan?.kind == MealPlanKindEnum.Breakfast ||
          lodging.price?.mealPlan?.kind == MealPlanKindEnum.AllInclusive
      );
    }
    return false;
  }
);

export const getFirstIndexOfHomesInAvailabilityLodgingsResults = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): number => {
    if (lodgings) {
      return lodgings.findIndex(
        (lodging) => lodging.stayType == StayType.Homes
      );
    }
    return -1;
  }
);

const getAllHotelAvailabilityFilters = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityHotelsOnSaleFilter,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getHotelAvailabilityStayTypeFilter,
  getHotelAvailabilityMealPlanTypeFilter,
  getShowPremiumStaysOnlyFilter,
  getShowLifestyleStaysOnlyFilter,
  getIsPremierCollectionEnabled,
  getIsLifestyleCollectionEnabled,
  (
    amenities,
    starRatings,
    maxPrice,
    hotelName,

    freeCancel,
    hotelsOnSaleOnly,

    isInPolicy,
    loyaltyPrograms,
    stayTypes,
    mealPlanTypes,
    showPremiumStaysOnlyFilter,
    showLifestyleStaysOnlyFilter,
    isPremierCollectionEnabled,
    isLifestyleCollectionEnabled
  ) => {
    return {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      hotelsOnSaleOnly,
      isInPolicy,
      loyaltyPrograms,
      stayTypes,
      mealPlanTypes,
      showPremiumStaysOnlyFilter: isPremierCollectionEnabled
        ? showPremiumStaysOnlyFilter
        : false,
      showLifestyleStaysOnlyFilter: isLifestyleCollectionEnabled
        ? showLifestyleStaysOnlyFilter
        : false,
    };
  }
);

export const getAvailabilityAppliedFilterTrackingProperties = createSelector(
  getAllHotelAvailabilityFilters,
  (filters) => {
    let starsFilter = filters.starRatings.map((star) => {
      switch (star) {
        case "One":
          return 1;
        case "Two":
          return 2;
        case "Three":
          return 3;
        case "Four":
          return 4;
        case "Five":
          return 5;
        default:
          return undefined;
      }
    });

    return {
      applied_filters_filter_amenities: filters.amenities,
      applied_filters_filter_star: starsFilter,
      applied_filters_filter_price_max:
        filters.maxPrice && filters.maxPrice > 0 ? filters.maxPrice : undefined,
      applied_filters_filter_hotel_name: filters.hotelName
        ? filters.hotelName
        : undefined,
      applied_filters_filter_free_cancellation: filters.freeCancel
        ? filters.freeCancel
        : undefined,
      applied_filters_filter_sales_only: filters.hotelsOnSaleOnly
        ? filters.hotelsOnSaleOnly
        : undefined,
      applied_filters_filter_in_policy: filters.isInPolicy
        ? filters.isInPolicy
        : undefined,
      applied_filters_filter_loyalty_programs: filters.loyaltyPrograms,
      applied_filters_filter_stay_type: filters.stayTypes,
      applied_filters_filter_premium_stay: filters.showPremiumStaysOnlyFilter
        ? filters.showPremiumStaysOnlyFilter
        : undefined,
    };
  }
);

export const getFilteredHotelAvailabilityLodgings = createSelector(
  getAllHotelAvailabilityFilters,
  getDeduplicatedLodgings,
  (filters, lodgings): Lodging[] => {
    const {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      hotelsOnSaleOnly,
      isInPolicy,
      loyaltyPrograms,
      stayTypes,
      mealPlanTypes,
      showPremiumStaysOnlyFilter,
      showLifestyleStaysOnlyFilter,
    } = filters;

    const meetsFilterPredicates = (lodging: Lodging) => {
      if (
        !performMealPlanTypeFilter(lodging, mealPlanTypes) ||
        !performStayTypeFilter(lodging, stayTypes) ||
        !performAmenitiesFilter(lodging, amenities) ||
        !performStarRatingsFilter(lodging, starRatings) ||
        !performMaxPriceFilter(lodging, maxPrice) ||
        !performHotelNameFilter(lodging, hotelName) ||
        !performPolicyFilter(lodging, isInPolicy) ||
        !performPremierAndLifestyleCollectionFilter(
          lodging,
          showPremiumStaysOnlyFilter
        ) ||
        !performLifestyleCollectionFilter(
          lodging,
          showLifestyleStaysOnlyFilter
        ) ||
        !performFreeCancelFilter(lodging, freeCancel) ||
        !performHotelsOnSaleFilter(lodging, hotelsOnSaleOnly) ||
        !performLoyaltyProgramFilter(lodging, loyaltyPrograms)
      ) {
        return false;
      }

      return true;
    };

    return (lodgings ?? []).filter(meetsFilterPredicates);
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getHotelAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings.length === 0 &&
      availabilityCallState === HotelAvailabilityCallState.Complete
    );
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching =
  createSelector(
    getFilteredHotelAvailabilityLodgings,
    getHotelAvailabilityCallState,
    (lodgings, availabilityCallState): boolean => {
      return (
        lodgings.length === 0 &&
        (availabilityCallState === HotelAvailabilityCallState.Complete ||
          availabilityCallState ===
            HotelAvailabilityCallState.FollowUpSearchCallSuccess)
      );
    }
  );

export const getFilteredHotelAvailabilityLodgingsContainPremierCollection =
  createSelector(getFilteredHotelAvailabilityLodgings, (lodgings): boolean => {
    return lodgings.some((lodging) => {
      return (
        lodging.isLifestyleCollection ||
        lodging.isLuxuryCollection ||
        lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle ||
        lodging.lodgingCollection === LodgingCollectionEnum.Premier
      );
    });
  });

export const getIsUnfilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getDeduplicatedLodgings,
  getHotelAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings?.length === 0 &&
      availabilityCallState === HotelAvailabilityCallState.Complete
    );
  }
);

export const getHotelAvailabilitySortOption = (state: IStoreState) =>
  state.hotelAvailability.sortOption;

export const getTotalPropertyCount = (state: IStoreState) =>
  state.hotelAvailability.totalPropertyCount;

export const getFilteredAndSortedHotelAvailabilityLodgings = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getAllHotelAvailabilityFilters,
  getHotelAvailabilitySortOption,
  (lodgings, filters, sortOption): Lodging[] => {
    const { availableLodgings, unavailableLodgings } = lodgings.reduce(
      (prev, curr) => {
        const available =
          typeof curr.available === "undefined" ? true : curr.available;

        if (available) prev.availableLodgings.push(curr);
        else prev.unavailableLodgings.push(curr);

        return prev;
      },
      {
        availableLodgings: [] as Lodging[],
        unavailableLodgings: [] as Lodging[],
      }
    );

    const sortedAvailableHotels = (() => {
      switch (sortOption) {
        case HotelAvailabilitySortOption.Pricing:
          return orderByPriceLowToHigh(availableLodgings);
        case HotelAvailabilitySortOption.StarRating:
          return orderByStarRatingHighToLow(availableLodgings);
        case HotelAvailabilitySortOption.Recommended:
          if (filters.showPremiumStaysOnlyFilter) {
            return availableLodgings.slice().sort((a, b) => {
              if (a.lodgingCollection !== b.lodgingCollection) {
                if (a.lodgingCollection === LodgingCollectionEnum.Premier) {
                  return -1;
                } else if (
                  b.lodgingCollection === LodgingCollectionEnum.Premier
                ) {
                  return 1;
                } else if (
                  a.lodgingCollection === LodgingCollectionEnum.Lifestyle
                ) {
                  return -1;
                } else if (
                  b.lodgingCollection === LodgingCollectionEnum.Lifestyle
                ) {
                  return 1;
                }
              }
              return 0;
            });
          } else {
            return availableLodgings;
          }
      }
    })();

    return sortedAvailableHotels.concat(unavailableLodgings);
  }
);

export const getMapBound = (state: IStoreState) =>
  state.hotelAvailability.mapBound;

export const getOpenDatesModal = (state: IStoreState) =>
  state.hotelAvailability.openDatesModal;

export const getHotelAvailabilitySearchLodgingIds = (state: IStoreState) =>
  state.hotelAvailability.searchLodgingIds;

export const getHotelAvailabilityFromDate = (state: IStoreState) =>
  state.hotelAvailability.searchFromDate;

export const getHotelAvailabilityUntilDate = (state: IStoreState) =>
  state.hotelAvailability.searchUntilDate;

export const getHotelAvailabilityAdultsCount = (state: IStoreState) =>
  state.hotelAvailability.searchAdultsCount;

export const getHotelAvailabilityChildren = (state: IStoreState) =>
  state.hotelAvailability.searchChildren;

export const getHotelAvailabilityRoomsCount = (state: IStoreState) =>
  state.hotelAvailability.searchRoomsCount;

export const getHotelsAvailabilityPetsCount = (state: IStoreState) =>
  state.hotelAvailability.searchPetsCount;

export const getSearchedNightCount = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }

    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getHotelAvailabilitySearchLocationResult = (
  state: IStoreState
) => {
  return state.hotelAvailability.searchLocationResult;
};

export const getHotelQueryParams = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getHotelsAvailabilityPetsCount,
  getSelectedAccountIndex,
  getSelectedLodgingIndex,
  getHotelAvailEntryPoint,
  (
    fromDate,
    untilDate,
    adultsCount,
    children,
    pets,
    selectedAccountIndex,
    selectedLodgingIndex,
    entryPoint
  ) => ({
    fromDate,
    untilDate,
    adultsCount,
    children,
    pets,
    selectedAccountIndex,
    selectedLodgingIndex,
    entryPoint,
  })
);

export const getUnavailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getLuxuryCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Premier
    ).length
);

export const getLifestyleCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    ).length
);

export const getLodgingPromotionsCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) => !!lodging.bestPromotionThisLodging).length
);

export const getHotelEntryProperties = createSelector(
  getAgentEmail,
  getLocation,
  getFromDate,
  getUntilDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getAdultsCount,
  getChildrenCount,
  getRoomsCount,
  getPetsCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getHotelAvailEntryPoint,
  getApplyUserHotelPreferences,
  getPaymentMethods,
  getHotelAvailabilityTrackingProperties,
  getAppliedHotelAvailabilityFilterCount,
  getHotelAvailabilitySortOption,
  getAvailabilityAppliedFilterTrackingProperties,
  (
    agentEmail,
    location,
    fromDate,
    untilDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    numChildren,
    roomCount,
    petsCount,
    credit,
    creditBreakdown,
    hotelAvailEntryPoint,
    applyUserHotelPreferences,
    paymentMethods,
    availabilityTrackingProperties,
    filtersCount,
    sortOrder,
    appliedFiltersTracking
  ): ITrackingProperties<HotelEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        // this is the best effort way to parse the country out of the location label
        // this value will not be correct all of the time since address are known to be difficult to parse
        // it should only be used for tracking purposes and not relied on for anything else
        country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        number_of_properties: 0,
        number_of_guests: numAdults + numChildren,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        advance: fromDate ? dayjs(fromDate).diff(dayjs(), "day") : undefined,
        rooms_searched: roomCount,
        pets_searched: petsCount,
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        entry_type: hotelAvailEntryPoint,
        los:
          fromDate && untilDate
            ? dayjs(untilDate).diff(fromDate, "day")
            : undefined,
        preferences_applied: applyUserHotelPreferences,
        card_on_file: paymentMethods.length > 0,
        ...location?.trackingPropertiesV2?.properties,
        ...availabilityTrackingProperties?.properties,
        adults_count: numAdults,
        children_count: numChildren,
        number_of_filters_applied: filtersCount,
        sort_order: sortOrder,
        destination_name: location?.label ?? "",
        ...appliedFiltersTracking,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
        availabilityTrackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getViewedHotelListProperties = createSelector(
  getHotelEntryProperties,
  getSelectedAccount,
  getHotelAvailabilityTrackingProperties,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getHotelBestOfferOverall,
  getLuxuryCollectionLodgingCount,
  getLodgingPromotionsCount,
  getLifestyleCollectionLodgingCount,
  getHotelAvailabilitySearchLocationResult,
  getFilteredHotelAvailabilityLodgings,
  (
    properties,
    account,
    trackingProperties,
    unavailableLodgingCount,
    availableLodgingCount,
    bestOverallOffer,
    luxuryLodgingCount,
    lodgingPromotionsCount,
    lifestyleLodgingCount,
    location,
    filteredLodgings
  ): ITrackingProperties<ViewedHotelListProperties> => {
    return {
      properties: {
        ...properties.properties,
        preferences_applied: undefined,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        ...bestOverallOffer?.trackingPropertiesV2?.properties,
        has_offer: !!bestOverallOffer,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pc_shown: luxuryLodgingCount || 0,
        has_pc_properties: (luxuryLodgingCount || 0) > 0,
        has_lodging_promotions: lodgingPromotionsCount,
        lc_shown: lifestyleLodgingCount || 0,
        has_lc_properties: (lifestyleLodgingCount || 0) > 0,
        city_name: (
          location?.id as { lodgingSelection: Place }
        )?.lodgingSelection.placeTypes.includes("locality")
          ? location?.label
          : undefined,
        state:
          filteredLodgings.length > 0
            ? filteredLodgings[0].lodging.state?.name
            : undefined,
        no_availability: !filteredLodgings.length,
        google_place_id: (location?.id as IIdLodgings)?.lodgingSelection
          ?.placeId,
        ...trackingProperties?.properties,
      },
      encryptedProperties: [
        bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
        trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getHasViewedUnavailableHotel = (state: IStoreState) =>
  state.hotelAvailability.hasViewedUnavailableHotel;

export const getExistingHotelsAvailabilityRequestParameters = createSelector(
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getHotelAvailabilityRoomsCount,
  getHotelsAvailabilityPetsCount,
  (
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    petsCount
  ): {
    location: IResult | null;
    fromDate: Date | null;
    untilDate: Date | null;
    adultsCount: number | null;
    children: number[];
    roomsCount: number;
    petsCount: number;
  } => ({
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    petsCount,
  })
);
export const areRequestParametersChanged = createSelector(
  getExistingHotelsAvailabilityRequestParameters,
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildren,
  getRoomsCount,
  getPetsCount,
  (
    requestParameters,
    locationSearched,
    fromDateSearched,
    untilDateSearched,
    adultsCountSearched,
    childrenSearched,
    roomsCountSearched,
    petsCountSearched
  ): boolean => {
    return (
      (locationSearched !== null &&
        !isEqual(locationSearched, requestParameters.location)) ||
      !isEqual(fromDateSearched, requestParameters.fromDate) ||
      !isEqual(untilDateSearched, requestParameters.untilDate) ||
      !isEqual(adultsCountSearched, requestParameters.adultsCount) ||
      !isEqual(childrenSearched, requestParameters.children) ||
      !isEqual(roomsCountSearched, requestParameters.roomsCount) ||
      !isEqual(petsCountSearched, requestParameters.petsCount)
    );
  }
);

export const getSearchLocationLabel = (state: IStoreState) => {
  const isMapArea = !!getViewHotelsNearLocation(state) || !!getMapBound(state);
  const location = getLocation(state);
  return location?.label ?? (isMapArea ? "Map area" : null);
};

export const getViewHotelsNearLocation = (state: IStoreState) =>
  state.hotelAvailability.viewHotelsNearLocation;

export const getViewHotelsNearLocationCategories = (state: IStoreState) =>
  state.hotelAvailability.viewHotelsNearLocationCategories;

export const getViewHotelsNearLocationCategoriesLoading = (
  state: IStoreState
) => state.hotelAvailability.viewHotelsNearLocationCategoriesLoading;

export const getViewHotelsNearAvailabilityProperties = createSelector(
  getViewedHotelListProperties,
  getViewHotelsNearLocation,
  (
    viewedHotelListProperties,
    viewHotelsNear
  ): ITrackingProperties<ViewHotelsNearAvailabilityProperties> => {
    return {
      properties: {
        ...viewedHotelListProperties.properties,
        poi_availability: viewHotelsNear?.label || "",
      },
      encryptedProperties: [...viewedHotelListProperties.encryptedProperties],
    };
  }
);

const filterCollectionLodgings = (
  lodgings: Lodging[],
  collections: LodgingCollectionEnum[]
) =>
  lodgings.filter(
    (lodging) =>
      collections.indexOf(
        lodging.lodgingCollection ?? LodgingCollectionEnum.NoCollection
      ) > -1
  );

const countTopCollectionLodgings = (
  lodgings: Lodging[],
  collections: LodgingCollectionEnum[]
) => {
  let acc = 0;
  for (let i = 0; i < lodgings.length; i++) {
    if (
      collections.indexOf(
        lodgings[i].lodgingCollection ?? LodgingCollectionEnum.NoCollection
      ) > -1
    ) {
      acc++;
    } else {
      return acc;
    }
  }
  return acc;
};

export const getPremiumStaysLodgingsCount = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getDeduplicatedLodgings,
  (filtered, all) => ({
    filtered: filterCollectionLodgings(filtered, [
      LodgingCollectionEnum.Premier,
      LodgingCollectionEnum.Lifestyle,
    ]).length,
    all: filterCollectionLodgings(all, [
      LodgingCollectionEnum.Premier,
      LodgingCollectionEnum.Lifestyle,
    ]).length,
    top: countTopCollectionLodgings(filtered, [
      LodgingCollectionEnum.Premier,
      LodgingCollectionEnum.Lifestyle,
    ]),
  })
);

export const getLifestyleStaysLodgingsCount = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getDeduplicatedLodgings,
  (filtered, all) => ({
    filtered: filterCollectionLodgings(filtered, [
      LodgingCollectionEnum.Lifestyle,
    ]).length,
    all: filterCollectionLodgings(all, [LodgingCollectionEnum.Lifestyle])
      .length,
    top: countTopCollectionLodgings(filtered, [
      LodgingCollectionEnum.Lifestyle,
    ]),
  })
);

export const getHotelAvailabilityCentroids = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.centroid;
