import { Badge, Box } from "@material-ui/core";
import {
  ActionLink,
  BackButton,
  DatePickerButton,
  Header,
  HotelAvailabilityFilter,
  Icon,
  IconName,
  MobileSearchFieldButton,
  getCurrencySymbol,
  useDeviceTypes,
} from "halifax";
import React, { useContext, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { PATH_AVAILABILITY, PATH_HOME } from "../../../../utils/paths";
import { MobileLocationSelectionConnectorProps } from "./container";
import "./styles.scss";

import { useShowPolicyBanner } from "@capone/common";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import { CLEAR_ALL_FILTERS, IIdLodgings, IResult } from "redmond";
import config from "../../../../../../../cap1-application/b2b-base/src/utils/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../../App";
import {
  AVAILABLE,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  FREE_BREAKFAST_CANCEL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTEL_COLOR_CALENDAR,
  HOTEL_COLOR_CALENDAR_VARIANTS,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  SHOW_MEALPLAN,
  STAYS_SEARCH,
  addTrackingProperties,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { OccupancySelection } from "../../../search/components/MobileHotelSearchControl/components";
import { MobileCalendarPickerModal } from "../../../search/components/MobileHotelSearchControlV2/components/MobileCalendarPickerModal";
import { MobileLocationSearchModal } from "../../../search/components/MobileHotelSearchControlV2/components/MobileLocationSearchModal";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import { initialFilterState, initialState } from "../../reducer";
import { HotelAvailabilitySortOption } from "../../reducer/state";
import {
  shouldShowLifestyleStaysFilter,
  shouldShowPremiumStaysFilter,
} from "../utilities";
import * as textConstants from "./textConstants";

export const sortOptions = [
  HotelAvailabilitySortOption.Recommended,
  HotelAvailabilitySortOption.Pricing,
  HotelAvailabilitySortOption.StarRating,
];

export interface IMobileLocationSelectionProps
  extends RouteComponentProps,
    MobileLocationSelectionConnectorProps {
  fullScreen?: boolean;
  filterModalOpen: boolean;
  setFilterModalOpen: (open: boolean) => void;
}

export const MobileLocationSelection = (
  props: IMobileLocationSelectionProps
) => {
  const {
    searchLocation,
    locationLabel,
    history,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    petsCount,
    amenities,
    setAmenitiesFilter,
    starRatings,
    setStarRatingsFilter,
    stayTypes,
    setStayTypeFilter,
    mealPlanTypes,
    setMealPlanTypeFilter,
    showPremiumStaysOnly,
    setPremiumStaysOnly,
    showLifestyleStaysOnly,
    setLifestyleStaysOnly,
    maxPrice,
    setMaxPriceFilter,
    minMaxPriceRange,
    currency,
    hotelName,
    setHotelNameFilter,
    sortOption,
    setHotelAvailabilitySortOption,
    dateModalOpen,
    setDateModalOpen,
    fullScreen = false,
    hasFreeCancelFilter,
    setFreeCancelFilter,
    hasHotelsOnSaleFilter,
    setHotelsOnSaleFilter,
    isSearchingMap,
    setViewHotelsNearLocation,
    fetchInitialHotelAvailability,
    loyaltyPrograms,
    setLoyaltyProgramsFilter,
    isInPolicy,
    setPolicyFilter,
    shouldApplyUserHotelPreferences,
    userHotelPreferences,
    hasUserSetHotelPreferences,
    userHotelPreferencesCallState,
    setApplyUserHotelPreferences,
    filterModalOpen,
    setFilterModalOpen,
    premiumStaysLodgingCount,
    lifestyleStaysLodgingCount,
    walletItemCount,
    availabilityResultsHasHomesAndHotels,
  } = props;
  const [locationModalOpen, setLocationModalOpen] =
    React.useState<boolean>(false);
  const [localAdultsCount, setLocalAdultsCount] =
    React.useState<number>(adultsCount);
  const [localChildren, setLocalChildren] = React.useState<number[]>(children);
  const [localRoomsCount, setLocalRoomsCount] =
    React.useState<number>(roomsCount);
  const [localPetsCount, setLocalPetsCount] = React.useState<number>(petsCount);
  const [localLocation, setLocalLocation] = React.useState<IResult | null>(
    searchLocation
  );
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [travelWalletDrawerOpen, setTravelWalletDrawerOpen] =
    React.useState<boolean>(false);

  const { matchesMobile } = useDeviceTypes();

  React.useEffect(() => {
    setLocalAdultsCount(adultsCount);
  }, [adultsCount]);

  React.useEffect(() => {
    setLocalChildren(children);

    setLocalLocation(searchLocation);
  }, [children]);

  React.useEffect(() => {
    setLocalRoomsCount(roomsCount);
  }, [roomsCount]);

  React.useEffect(() => {
    setLocalPetsCount(petsCount);
  }, [petsCount]);

  React.useEffect(() => {
    setLocalLocation(searchLocation);
  }, [searchLocation]);

  React.useEffect(() => {
    setOpenCalendarModal(dateModalOpen);
  }, [dateModalOpen]);

  const expState = useExperiments();

  const { policies } = useContext(ClientContext);
  const showPolicyFilter = useShowPolicyBanner(policies);

  const isFreeBreakfastCancelExperiment =
    getExperimentVariant(expState.experiments, FREE_BREAKFAST_CANCEL) ===
    AVAILABLE;

  const isShowMealPlanExperiment =
    getExperimentVariant(expState.experiments, SHOW_MEALPLAN) === AVAILABLE;

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const colorCalendarExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_COLOR_CALENDAR,
    HOTEL_COLOR_CALENDAR_VARIANTS
  );

  const showPremiumStaysFilter = shouldShowPremiumStaysFilter(
    premiumStaysLodgingCount,
    expState.experiments
  );

  const showLifestyleStaysFilter = shouldShowLifestyleStaysFilter(
    lifestyleStaysLodgingCount,
    expState.experiments
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );
  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const isReadyToSearch =
    localLocation && fromDate && untilDate && localAdultsCount;

  const readyToReset = useMemo(
    () =>
      !isEqual(amenities, initialFilterState.amenities) ||
      !isEqual(starRatings, initialFilterState.starRatings) ||
      maxPrice !== initialFilterState.maxPrice ||
      !isEqual(hotelName, initialFilterState.hotelName) ||
      showPremiumStaysOnly !== initialState.showPremiumStaysOnly ||
      showLifestyleStaysOnly !== initialState.showLifestyleStaysOnly ||
      sortOption !== initialState.sortOption,
    [
      amenities,
      starRatings,
      showPremiumStaysOnly,
      showLifestyleStaysOnly,
      maxPrice,
      minMaxPriceRange,
      hotelName,
      sortOption,
    ]
  );

  const onSearchAgain = () => {
    if (
      localLocation &&
      localLocation.id &&
      (localLocation?.id as IIdLodgings).lodgingSelection.searchTerm
    ) {
      setViewHotelsNearLocation(null);
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          (localLocation?.id as IIdLodgings).lodgingSelection.searchTerm,
          fromDate,
          untilDate,
          localAdultsCount,
          localChildren,
          localRoomsCount,
          localPetsCount
        )}`
      );
      fetchInitialHotelAvailability({
        history: history,
        searchFromMap: false,
        searchHotelsNear: false,
        includeHomes: staysSearchEnabled,
      });
    }
  };
  const onSetLocalPassengerCount = (adults: number, children: number[]) => {
    setLocalAdultsCount(adults);
    setLocalChildren(children);
  };

  const handleOpenCalendarModal = (open: boolean) => {
    setOpenCalendarModal(open);
    setDateModalOpen(open);
  };

  const renderFilterHeader = () => (
    <Header
      className={clsx("mobile-hotel-availability-search-header", {
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
      left={
        <BackButton
          className="mobile-hotel-availability-go-back"
          onClick={() => history.push(PATH_HOME)}
        />
      }
      center={
        <Box className="mobile-hotel-availability-search-and-date">
          <MobileSearchFieldButton
            value={isSearchingMap ? "Map area" : searchLocation?.label}
            onClick={() => setLocationModalOpen(true)}
          />
          <DatePickerButton
            startDate={fromDate}
            endDate={untilDate}
            classes={["hotel-inline-juncture-input"]}
            renderCalendarPopup={() => (
              <MobileCalendarPickerModal
                openCalendarModal={openCalendarModal}
                setOpenCalendarModal={handleOpenCalendarModal}
                fullScreen
                showPricing={
                  colorCalendarExperimentVariant ===
                  HOTEL_COLOR_CALENDAR_WITH_PRICING
                }
              />
            )}
            onClick={() => handleOpenCalendarModal(true)}
            dateFormat={"MMM D"}
            variant="minimal"
          />
        </Box>
      }
      right={
        isGlobalMobileNavExperiment ? (
          <ActionLink
            className="mobile-hotel-availability-wallet-entry"
            onClick={() => setTravelWalletDrawerOpen(true)}
            content={
              <Badge
                className="wallet-count"
                badgeContent={walletItemCount ? "" : undefined}
              >
                <Icon name={IconName.TravelWalletIcon} />
              </Badge>
            }
          />
        ) : (
          <ActionLink
            className="mobile-hotel-availability-filter-entry"
            onClick={() => setFilterModalOpen(true)}
            content={
              <Badge
                className="filter-count"
                badgeContent={readyToReset ? "" : undefined}
              >
                <Icon name={IconName.Settings} />
              </Badge>
            }
          />
        )
      }
    />
  );

  return (
    <>
      {renderFilterHeader()}
      <MobileLocationSearchModal
        openLocationModal={locationModalOpen}
        setOpenLocationModal={setLocationModalOpen}
        history={history}
        onContinue={
          isReadyToSearch
            ? () => {
                onSearchAgain();
              }
            : undefined
        }
        searchOnContinue
        setLocalLocation={setLocalLocation}
        occupancySelectionComponent={
          <OccupancySelection
            onSetLocalPassengerCount={onSetLocalPassengerCount}
            onSetLocalRoomsCount={setLocalRoomsCount}
            onSetLocalPetsCount={setLocalPetsCount}
            localAdultsCount={localAdultsCount}
            localChildrenCount={localChildren}
            localRoomsCount={localRoomsCount}
            localPetsCount={localPetsCount}
            showTotalTravelers
            showClosePassengerModalButton
            showPets={staysSearchEnabled}
          />
        }
        onSetLocalPassengerCount={onSetLocalPassengerCount}
        setLocalRoomsCount={setLocalRoomsCount}
        setLocalPetsCount={setLocalPetsCount}
        className="availability-location-popup"
        headerText={textConstants.CHANGE_LOCATION}
        fullScreen={!!fullScreen}
      />
      <HotelAvailabilityFilter
        openModal={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        isMobile={matchesMobile}
        amenities={amenities}
        setAmenities={setAmenitiesFilter}
        starRatings={starRatings}
        setStarRatings={setStarRatingsFilter}
        premiumStays={
          showPremiumStaysFilter
            ? {
                value: showPremiumStaysOnly,
                set: (enable) =>
                  setPremiumStaysOnly(enable, {
                    location: "all_filters_modal",
                    searched_market: locationLabel,
                  }),
                premiumHotelsCount: premiumStaysLodgingCount.filtered,
              }
            : undefined
        }
        lifestyleStays={
          showLifestyleStaysFilter && !showPremiumStaysFilter
            ? {
                value: showLifestyleStaysOnly,
                set: (enable) =>
                  setLifestyleStaysOnly(enable, {
                    location: "all_filters_modal",
                    searched_market: locationLabel,
                  }),
                lifestyleHotelsCount: lifestyleStaysLodgingCount.filtered,
              }
            : undefined
        }
        hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
        hotelMaxPrice={maxPrice}
        setHotelMaxPrice={setMaxPriceFilter}
        currencySymbol={getCurrencySymbol(currency)}
        hotelName={hotelName}
        setHotelName={setHotelNameFilter}
        readyToReset={readyToReset}
        isFreeCancelExperiment={isFreeBreakfastCancelExperiment}
        hasFreeCancelFilter={hasFreeCancelFilter}
        setFreeCancelFilter={setFreeCancelFilter}
        isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
        hasHotelsOnSaleFilter={hasHotelsOnSaleFilter}
        setHotelsOnSaleFilter={setHotelsOnSaleFilter}
        sortOptions={sortOptions.map((value) => ({
          value,
          label: textConstants.sortOptionLabel[value],
        }))}
        selectedSortOption={sortOption}
        setSelectedSortOption={(value) =>
          setHotelAvailabilitySortOption(value as HotelAvailabilitySortOption)
        }
        defaultSortOption={initialState.sortOption}
        titles={textConstants.FILTER_MODAL_TITLES}
        loyaltyPrograms={loyaltyPrograms}
        setLoyaltyPrograms={setLoyaltyProgramsFilter}
        isInPolicy={isInPolicy}
        setIsInPolicy={setPolicyFilter}
        tenant={config.TENANT}
        showHotelPreferencesBanner={isCustomerProfileExperiment}
        userHotelPreferencesCallState={userHotelPreferencesCallState}
        userHasSetHotelPreferences={hasUserSetHotelPreferences}
        shouldApplyUserHotelPreferences={shouldApplyUserHotelPreferences}
        setShouldApplyUserHotelPreferences={setApplyUserHotelPreferences}
        userHotelPreferences={userHotelPreferences}
        onReset={() => {
          trackEvent({
            eventName: CLEAR_ALL_FILTERS,
            properties: addTrackingProperties(expState.trackingProperties),
          });
        }}
        showStayTypeFilter={availabilityResultsHasHomesAndHotels}
        stayTypes={stayTypes}
        setStayTypes={setStayTypeFilter}
        showMealPlanTypeFilter={isShowMealPlanExperiment}
        mealPlanTypes={mealPlanTypes}
        setMealPlanTypes={setMealPlanTypeFilter}
        showPolicyFilter={showPolicyFilter}
      />

      {isGlobalMobileNavExperiment && (
        <TravelWalletDrawer
          drawerOpen={travelWalletDrawerOpen}
          setDrawerOpen={setTravelWalletDrawerOpen}
        />
      )}
    </>
  );
};
