import { Box, Dialog, Slide, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  NewTag,
  useDeviceTypes,
} from "halifax";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  AVAILABLE,
  getExperimentVariant,
  STAYS_VACATION_RENTALS_AWARENESS_MODAL,
  useExperiments,
} from "../../../../context/experiments";
import {
  MODAL_ALERT_CHOICE,
  ModalAlertChoiceProperties,
  ModalButtonType,
  ModalCategoryType,
  RewardsAccount,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  VR_AWARENESS_EARN_ENHANCEMENT,
  VR_AWARENESS_EARN_ENHANCEMENT_TITLE,
} from "../../textConstants";

export interface VacationRentalAwarenessModalProps {
  largestValueAccount?: RewardsAccount;
}

export const VacationRentalAwarenessModal = ({
  largestValueAccount,
}: VacationRentalAwarenessModalProps) => {
  const expState = useExperiments();

  const staysVRAwarenessModalEnabled =
    getExperimentVariant(
      expState.experiments,
      STAYS_VACATION_RENTALS_AWARENESS_MODAL
    ) === AVAILABLE;

  const { matchesMobile } = useDeviceTypes();

  const [open, setOpen] = useState<boolean>(false);

  const localStorageKey = "vr_awareness_modal_seen";
  const modalSeen = localStorage.getItem(localStorageKey) != null;

  useEffect(() => {
    if (staysVRAwarenessModalEnabled && !modalSeen && largestValueAccount) {
      setOpen(true);
    }
  }, [staysVRAwarenessModalEnabled, largestValueAccount]);

  const onCloseHandler = (isPrimary: boolean) => {
    localStorage.setItem(localStorageKey, "true");
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        lob: "vacation rentals",
        type: "stays_vr_awareness_modal",
        category: ModalCategoryType.FEATURE,
        primary_button: "search_stays",
        funnel: "hotels",
        button_choice: isPrimary
          ? ModalButtonType.PRIMARY
          : ModalButtonType.CLOSE,
      } as ModalAlertChoiceProperties,
    });
    setOpen(false);
  };

  const closeRef = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    if (open)
      setTimeout(() => {
        closeRef.current?.focus();
      }, 2000);
  }, [open]);

  let earnTitle = "",
    earnAmountText = "";
  if (largestValueAccount) {
    const homesMultiplier = largestValueAccount.earn.homesMultiplier;
    const currency =
      largestValueAccount.rewardsBalance.currencyDescription ??
      largestValueAccount.rewardsBalance.currency;

    earnTitle = VR_AWARENESS_EARN_ENHANCEMENT_TITLE(homesMultiplier, currency);
    earnAmountText = VR_AWARENESS_EARN_ENHANCEMENT(homesMultiplier, currency);
  }

  return (
    <Dialog
      TransitionProps={{ role: "none", direction: "up" } as never}
      TransitionComponent={Slide}
      PaperProps={{
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
        style: {
          borderRadius: "8px",
        },
      }}
      open={open}
      role="dialog"
      onClose={() => onCloseHandler(false)}
      fullScreen={matchesMobile}
    >
      <Box
        aria-label={`Earn miles on vacation rentals`}
        className="vacation-rentals-awareness-modal"
      >
        {/* Header */}
        <Box
          sx={{
            py: matchesMobile ? 2 : 4,
            px: matchesMobile ? 4 : 6,
            bgcolor: "#013D58",
            position: "relative",
          }}
        >
          <Box>
            <ActionLink
              linkRef={closeRef}
              onClick={() => onCloseHandler(false)}
              content={
                <CloseButtonIcon className="vr-awareness-modal-alert-close-button" />
              }
              label="Close"
              showTappableArea={true}
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            />
            <Icon
              name={IconName.CaponeTravelWhiteText}
              ariaLabel="CapitalOne Logo"
            />
            <Box
              style={{
                display: "flex",
                marginTop: "20px",
                gap: "15px",
                flexDirection: "column",
              }}
            >
              <NewTag enabled={true} />
              {!!earnTitle && (
                <Typography
                  variant="h2"
                  style={{
                    fontSize: matchesMobile ? "28px" : "34px",
                    color: "#fff",
                  }}
                >
                  {earnTitle}
                </Typography>
              )}
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                }}
              >
                We've added{" "}
                <strong style={{ color: "#fff" }}>
                  professionally managed vacation rentals
                </strong>{" "}
                across the US.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          bgcolor="white"
          flexDirection="column"
          px={matchesMobile ? 4 : 6}
          py={4}
          style={{ gap: "20px" }}
          pb={matchesMobile ? "125px" : 0}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ gap: "20px" }}
          >
            <Icon
              className="vr-awareness-modal-icon"
              name={IconName.BadgeRibbonCircle}
              ariaLabel="Ribbon icon"
            />
            <Typography
              style={{
                color: "#676868",
              }}
            >
              Choose from thousands of{" "}
              <strong>professionally managed vacation rentals </strong>
              across the US
              {!!earnAmountText ? (
                <>
                  {" "}
                  and <strong>earn {earnAmountText}</strong> on all vacation
                  rental bookings
                </>
              ) : (
                <></>
              )}
              .
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ gap: "20px" }}
          >
            <Icon
              name={IconName.SettingsCircle}
              className="vr-awareness-modal-icon"
              ariaLabel="Filter icon"
            />
            <Typography
              style={{
                color: "#676868",
              }}
            >
              <strong>Filter by property type</strong> to only view vacation
              rentals once you've searched for a location.
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ gap: "20px" }}
          >
            <Icon
              name={IconName.HomeIconCircle}
              className="vr-awareness-modal-icon"
              ariaLabel="Vacation rental icon"
            />
            <Typography
              style={{
                color: "#676868",
              }}
            >
              <strong>
                You may see a larger selection of vacation rentals
              </strong>{" "}
              in some US locations compared to others.
            </Typography>
          </Box>
        </Box>

        {matchesMobile ? (
          <Box
            position="fixed"
            bottom="0px"
            boxShadow="0px -2px 10px rgba(0, 0, 0, 0.2)"
            width="100%"
          >
            <Box
              paddingLeft="15px"
              paddingRight="15px"
              paddingBottom="25px"
              style={{ backgroundColor: "white" }}
            >
              <ActionButton
                className="vr-awareness-modal-mobile-search-button"
                buttonClassName="cta-button-inner"
                onClick={() => onCloseHandler(true)}
                message="Search stays"
              />
            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end" py={4} pr={4}>
            <ActionButton
              defaultStyle="h4r-primary"
              buttonClassName="vr-awareness-modal-desktop-search-button"
              ariaLabelText="Search stays button"
              message="Search stays"
              onClick={() => {
                onCloseHandler(true);
              }}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
