import { types } from "halifax";
import {
  AvailabilityRequestEnum,
  AvailabilityResponseEnum,
  CallState,
  Initial,
} from "redmond";
import { actionTypes, actions } from "../actions";
import {
  HotelAvailabilityCallState,
  HotelAvailabilitySortOption,
  IHotelAvailabilityState,
  IHotelFilterState,
} from "./state";

export const initialFilterState: IHotelFilterState = {
  isInPolicy: false,
  amenities: [],
  starRatings: [],
  maxPrice: 0,
  hotelName: "",
  freeCancel: false,
  hotelsOnSaleOnly: false,
  loyaltyPrograms: [],
  showPremiumStaysOnly: false,
  showLifestyleStaysOnly: false,
  stayTypes: [],
  mealPlanTypes: [],
};

export const initialState: IHotelAvailabilityState = {
  hotelAvailabilityCallState: HotelAvailabilityCallState.NotCalled,

  mapBound: null,
  searchLocation: null,
  searchLodgingIds: null,
  totalPropertyCount: null,

  searchFromDate: null,
  searchUntilDate: null,
  searchLocationResult: null,

  lodgingIdInFocus: null,
  lodgingIdHovered: null,

  hasViewedUnavailableHotel: false,

  sortOption: HotelAvailabilitySortOption.Recommended,

  openDatesModal: false,

  selectedLodgingIndex: null,
  searchAdultsCount: 2,
  searchChildren: [],
  searchRoomsCount: 1,
  searchPetsCount: 0,
  hotelAvailEntryPoint: undefined,

  viewHotelsNearLocation: null,
  viewHotelsNearLocationCategories: [],
  viewHotelsNearLocationCategoriesLoading: false,

  userHotelPreferences: undefined,
  userHotelPreferencesCallState: CallState.NotCalled,
  isPremierCollectionEnabled: false,
  isLifestyleCollectionEnabled: false,

  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,

  ...initialFilterState,
};

export const reducer = types.exhaustiveReducer(
  initialState,
  (state, action: actions.HotelAvailabilityActions) => {
    switch (action.type) {
      case actionTypes.FETCH_INITIAL_HOTEL_AVAILABILITY:
        return {
          ...state,
          mapBound: action.searchFromMap ? state.mapBound : null,
          availabilityResponse:
            action.requestType === AvailabilityRequestEnum.InitialSearch
              ? undefined
              : state.availabilityResponse,
          hotelAvailabilityCallState:
            action.requestType === AvailabilityRequestEnum.InitialSearch
              ? action.searchFromMap || action.searchHotelsNear
                ? HotelAvailabilityCallState.InitialMapSearchCallInProcess
                : HotelAvailabilityCallState.InitialSearchCallInProcess
              : HotelAvailabilityCallState.FollowUpSearchCallInProcess,
        };
      case actionTypes.FETCH_MORE_HOTEL_AVAILABILITY:
        return {
          ...state,
          availabilityResponse:
            action.requestType === AvailabilityRequestEnum.InitialSearch
              ? undefined
              : state.availabilityResponse,
          hotelAvailabilityCallState:
            action.requestType === AvailabilityRequestEnum.InitialSearch
              ? HotelAvailabilityCallState.InitialSearchCallInProcess
              : HotelAvailabilityCallState.FollowUpSearchCallInProcess,
        };

      case actionTypes.SET_HOTEL_AVAIL_ENTRY_POINT:
        return {
          ...state,
          hotelAvailEntryPoint: action.entryPoint,
        };

      case actionTypes.SET_POLICY_FILTER:
        return {
          ...state,
          isInPolicy: action.isInPolicy,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_LOYALTY_PROGRAMS_FILTER:
        return {
          ...state,
          loyaltyPrograms: [...action.loyaltyPrograms],
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_HOTEL_AVAILABILITY_RESULTS:
        const isInitialRequest =
          action.payload.AvailabilityResponse ===
          AvailabilityResponseEnum.Initial;
        const hasCompletedRequest = !action.payload.nextPageToken;
        const isLodgingsEmpty = action.payload.lodgings.length === 0;
        if (isInitialRequest) {
          return {
            ...state,
            availabilityResponse: action.payload,
            searchLocation: isLodgingsEmpty
              ? state.searchLocation
              : action.payload.lodgings.filter(
                  (lodging) =>
                    !lodging.isLuxuryCollection &&
                    !lodging.isLifestyleCollection
                )[0]?.lodging.location || state.searchLocation,
            hotelAvailabilityCallState: hasCompletedRequest
              ? HotelAvailabilityCallState.Complete
              : HotelAvailabilityCallState.InitialSearchCallSuccess,
            totalPropertyCount:
              (action.payload as Initial).totalPropertyCount || null,
          };
        }

        return {
          ...state,
          availabilityResponse: {
            ...action.payload,
            // each FollowUpSearch fetches more hotels based on the specified page size;
            // the new lodging result continues from where the previous call ends.
            lodgings: [
              ...(state.availabilityResponse?.lodgings || []),
              ...action.payload.lodgings,
            ],
            bestOverallOffer:
              action.payload.bestOverallOffer ??
              state.availabilityResponse?.bestOverallOffer,
          },
          searchLocation: state.searchLocation,
          hotelAvailabilityCallState: hasCompletedRequest
            ? HotelAvailabilityCallState.Complete
            : HotelAvailabilityCallState.FollowUpSearchCallSuccess,
        };

      case actionTypes.SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED:
        return {
          ...state,
          hotelAvailabilityCallState: HotelAvailabilityCallState.Failed,
        };

      case actionTypes.SET_AMENITIES_FILTER:
        return {
          ...state,
          amenities: [...action.amenities],
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_STAR_RATINGS_FILTER:
        return {
          ...state,
          starRatings: [...action.starRatings],
          showPremiumStaysOnly: false,
          showLifestyleStaysOnly: false,
          filtersBannerMessage: state.showPremiumStaysOnly
            ? "Premium stays filter has been cleared. You’re viewing all stays that match your rating selection."
            : state.showLifestyleStaysOnly
            ? "Lifestyle Collection filter has been cleared. You’re viewing all stays that match your rating selection."
            : undefined,
        };

      case actionTypes.SET_STAY_TYPES_FILTER:
        return {
          ...state,
          stayTypes: action.stayTypes,
          showPremiumStaysOnly: false,
          showLifestyleStaysOnly: false,
          filtersBannerMessage: state.showPremiumStaysOnly
            ? "Premium stays filter has been cleared. You’re viewing all stays that match your stay type selection."
            : state.showLifestyleStaysOnly
            ? "Lifestyle Collection filter has been cleared. You’re viewing all stays that match your stay type selection."
            : undefined,
        };

      case actionTypes.SET_MEAL_PLAN_TYPES_FILTER:
        return {
          ...state,
          mealPlanTypes: action.mealPlanTypes,
          showPremiumStaysOnly: false,
          showLifestyleStaysOnly: false,
          filtersBannerMessage: state.showPremiumStaysOnly
            ? "Premium stays filter has been cleared. You’re viewing all hotels that match your rating selection."
            : state.showLifestyleStaysOnly
            ? "Lifestyle Collection filter has been cleared. You’re viewing all hotels that match your rating selection."
            : undefined,
        };

      case actionTypes.SET_MAX_PRICE_FILTER:
        return {
          ...state,
          maxPrice: action.maxPrice,
        };

      case actionTypes.SET_HOTEL_NAME_FILTER:
        return {
          ...state,
          hotelName: action.hotelName,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_FREE_CANCEL_FILTER:
        return {
          ...state,
          freeCancel: action.freeCancel,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_HOTELS_ON_SALE_FILTER:
        return {
          ...state,
          hotelsOnSaleOnly: action.saleOnly,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_HOTEL_SORT_OPTION:
        return {
          ...state,
          sortOption: action.sortOption,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_PREMIUM_STAYS_FILTER:
        return {
          ...state,
          showPremiumStaysOnly: action.showPremiumStaysOnly,
          showLifestyleStaysOnly: false,
          starRatings: [],
          stayTypes: [],
          mealPlanTypes: [],
          filtersBannerMessage:
            state.starRatings.length > 0 && state.stayTypes.length > 0
              ? "The star rating and stay type filters that were previously applied have been reset. You are now only viewing Premium stays in this location."
              : state.starRatings.length > 0
              ? "The star rating filter that was previously applied has been reset. You are now only viewing Premium stays in this location."
              : state.stayTypes.length > 0
              ? "The stay type filter that was previously applied has been reset. You are now only viewing Premium stays in this location."
              : state.mealPlanTypes.length > 0
              ? "The meal plan type filter that was previously applied has been reset. You are now only viewing Premium stays in this location."
              : undefined,
        };

      case actionTypes.SET_LIFESTYLE_STAYS_FILTER:
        return {
          ...state,
          showLifestyleStaysOnly: action.showLifestyleStaysOnly,
          showPremiumStaysOnly: false,
          starRatings: [],
          stayTypes: [],
          filtersBannerMessage:
            state.starRatings.length > 0 && state.stayTypes.length > 0
              ? "The star rating and stay type filters that were previously applied have been reset. You are now only viewing Lifestyle Collection hotels in this location."
              : state.starRatings.length > 0
              ? "The star rating filter that was previously applied has been reset. You are now only viewing Lifestyle Collection hotels in this location."
              : state.stayTypes.length > 0
              ? "The stay type filter that was previously applied has been reset. You are now only viewing Lifestyle Collection hotels in this location."
              : undefined,
        };

      case actionTypes.SET_LODGING_ID_IN_FOCUS:
        return {
          ...state,
          lodgingIdInFocus: action.lodgingId,
          lodgingIdHovered: action.lodgingId,
        };

      case actionTypes.SET_LODGING_ID_HOVERED:
        return {
          ...state,
          lodgingIdHovered: action.lodgingId,
        };

      case actionTypes.SET_MAP_BOUND:
        return {
          ...state,
          mapBound: action.mapBound,
        };

      case actionTypes.SET_OPEN_DATES_MODAL:
        return {
          ...state,
          openDatesModal: action.openDatesModal,
        };

      case actionTypes.SET_SEARCHED_DATES:
        return {
          ...state,
          searchFromDate: action.searchedFromDate,
          searchUntilDate: action.searchedUntilDate,
        };

      case actionTypes.SET_SEARCHED_LOCATION_RESULT:
        return {
          ...state,
          searchLocationResult: action.searchedLocationResult,
        };

      case actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL:
        return {
          ...state,
          hasViewedUnavailableHotel: true,
        };

      case actionTypes.SET_SELECTED_LODGING_INDEX:
        return {
          ...state,
          selectedLodgingIndex: action.index,
        };
      case actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS:
        const { adults, children } = action.counts;
        return {
          ...state,
          searchAdultsCount: adults,
          searchChildren: children,
        };
      case actionTypes.SET_SEARCHED_ROOMS_COUNT:
        return {
          ...state,
          searchRoomsCount: action.rooms,
        };
      case actionTypes.SET_SEARCHED_PETS_COUNT:
        return {
          ...state,
          searchPetsCount: action.pets,
        };
      case actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION:
        const { location } = action;

        return {
          ...state,
          viewHotelsNearLocation: location ? { ...location } : null,
          viewHotelsNearLocationCategoriesLoading: false,
        };

      case actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES:
        const { categories } = action;
        return {
          ...state,
          viewHotelsNearLocationCategories: categories,
          viewHotelsNearLocationCategoriesLoading: false,
        };

      case actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES:
        return {
          ...state,
          viewHotelsNearLocationCategories: [],
          viewHotelsNearLocationCategoriesLoading: true,
        };

      case actionTypes.FETCH_USER_HOTEL_PREFERENCES:
        return { ...state, userHotelPreferencesCallState: CallState.InProcess };

      case actionTypes.SET_USER_HOTEL_PREFERENCES:
        return {
          ...state,
          userHotelPreferences: action.hotelPreferences,
          userHotelPreferencesCallState: CallState.Success,
        };

      case actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED:
        return { ...state, userHotelPreferencesCallState: CallState.Failed };

      case actionTypes.SET_IS_PREMIER_COLLECTION_ENABLED:
        return { ...state, isPremierCollectionEnabled: action.isEnabled };

      case actionTypes.SET_IS_LIFESTYLE_COLLECTION_ENABLED:
        return { ...state, isLifestyleCollectionEnabled: action.isEnabled };

      case actionTypes.RESET_HOTEL_AVAILABILITY_CALL_STATE:
        return {
          ...state,
          hotelAvailabilityCallState: initialState.hotelAvailabilityCallState,
        };

      case actionTypes.LIST_PAYMENT_METHODS:
        return {
          ...state,
          listPaymentMethodCallState: CallState.InProcess,
        };

      case actionTypes.SET_PAYMENT_METHODS:
        return {
          ...state,
          paymentMethods: action.paymentMethods,
          listPaymentMethodCallState: CallState.Success,
        };

      case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
        return {
          ...state,
          listPaymentMethodCallState: CallState.Failed,
        };

      default:
        return types.ensureExhaustive(action);
    }
  }
);

export * from "./selectors";
