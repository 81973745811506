import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import GoogleMap, { LatLngBounds } from "google-maps-react-markers";
import { config } from "../../../../api/config";
import { MAP_STYLE } from "./const";
import {
  AVAILABILITY_CLUSTER_MAP,
  AVAILABLE,
  CLUSTER_MAP_VARIANTS,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { AvailabilityClusterMapConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {
  CorpLodging,
  HotelStarRatingEnum,
  LocationDescriptorEnum,
  Lodging,
  LodgingCollectionEnum,
  LodgingLocationEnum,
  LodgingPromotionType,
  RewardsAccount,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { Skeleton } from "@material-ui/lab";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import useSupercluster from "use-supercluster";
import {
  ActionButton,
  genericPriceFormatter,
  getTotalPriceText,
  HotelAvailabilityMapTooltip,
  Icon,
  IconName,
  isSignificantSavings,
} from "halifax";
import { HotelAvailabilityCallState } from "../../reducer/state";
import { getMarkerPrice, openHotelDetailsPage } from "./utils";
import clsx from "clsx";
import "./styles.scss";
import { useShowPolicyBanner } from "@capone/common";
import { ClientContext } from "../../../../App";
import { onOpenCompareBarTooltip } from "../../../../utils/events";

const DEFAULT_ZOOM_LEVEL = 13;
const DEFAULT_CENTER = { lat: 0, lng: 0 };

export interface IAvailabilityClusterMap
  extends AvailabilityClusterMapConnectorProps,
    RouteComponentProps {}

export const AvailabilityClusterMap = (props: IAvailabilityClusterMap) => {
  const {
    lodgings,
    searchLocation,
    callState,
    listHoveredLodgingId, // The lodging being hovered from the availability list
    listSelectedLodgingId, // The lodging selected from the availability list
    isVentureX, // Needed for the map marker + map tooltip
    searchedNightCount, // Needed for map tooltip
    accountReferenceId, // Needed for map tooltip
    largestValueAccount, // Needed fro map tooltip
    history,
    fetchInitialHotelAvailability, // Performs the initial search for stays availability
    setSearchedMapBound, // Sets the map bounds which is used by fetchInitialHotelAvailability

    // TODO: These props are only needed to openHotelDetailsPage. See if there's a way to simplify.
    searchLocationResult,
    hotelQueryParams,
    sortOrder,
    mapBounds,
    roomsCount,
  } = props;

  // Experiments
  const expState = useExperiments();
  const mapVariantExp = getExperimentVariantCustomVariants(
    expState.experiments,
    AVAILABILITY_CLUSTER_MAP,
    CLUSTER_MAP_VARIANTS
  );
  const showClustersExp = mapVariantExp === CLUSTER_MAP_VARIANTS[2];

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  // Initializing Map
  const mapRef = useRef<any>(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false); // Ensures the Google map is initialized with the API key
  const [centerCoordsLoaded, setCenterCoordsLoaded] = useState(false); // Ensures the center is parsed from the searchLocation and map pans
  const [initialMapLoaded, setInitialMapLoaded] = useState(false); // Shows the loading skeleleton while map variables are loading
  const [fetchingMoreListings, setFetchingMoreListings] = useState(true); // Shows the loading spinner on the map
  const [showClusters, setShowClusters] = useState(showClustersExp);
  const [debugMode] = useState(true);

  const onGoogleApiLoaded = ({ map }: any) => {
    mapRef.current = map;
    setGoogleMapsLoaded(true);
  };

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (searchLocation?.LodgingLocation == LodgingLocationEnum.Exact) {
      const lat = searchLocation?.coordinates?.lat || 0;
      const lng = searchLocation?.coordinates?.lon || 0;
      moveMapTo(lat, lng, DEFAULT_ZOOM_LEVEL);
      setCenterCoordsLoaded(true);
    } else {
      console.log("Not implemented - setting center from unknown");
    }
  }, [googleMapsLoaded, searchLocation]);

  useEffect(() => {
    if (googleMapsLoaded && centerCoordsLoaded) {
      setInitialMapLoaded(true);
    }
  }, [googleMapsLoaded, centerCoordsLoaded]);

  useEffect(() => {
    if (callState === HotelAvailabilityCallState.Complete) {
      setFetchingMoreListings(false);
    }
  }, [callState]);

  // Map Markers
  const [bounds, setBounds] = useState<any>([]);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM_LEVEL);
  const [hoveredMarker, setHoveredMarker] = useState<string>();
  const [selectedMarker, setSelectedMarker] = useState<string>();

  // Set the selected marker when a listing is selected from the list
  useEffect(() => {
    if (listSelectedLodgingId) {
      setSelectedMarker(listSelectedLodgingId);
    }
  }, [listSelectedLodgingId]);

  const points = useMemo(() => {
    if (!lodgings.length) {
      return [];
    }

    let lodgingsArr;

    // If Premier Collection is enabled, there may be multiple versions of the same lodging (regular vs. premier)
    if (isPremierCollectionEnabled) {
      const lodgingMap = new Map();

      lodgings.forEach((lodging) => {
        const id = lodging.lodging.id;
        if (
          !lodgingMap.has(id) ||
          lodging.lodgingCollection === LodgingCollectionEnum.Premier
        ) {
          lodgingMap.set(id, lodging);
        }
      });

      lodgingsArr = Array.from(lodgingMap.values());

      // Otherwise, just filter out the premier lodgings
    } else {
      lodgingsArr = lodgings.filter(
        (lodging) => lodging.lodgingCollection !== LodgingCollectionEnum.Premier
      );
    }

    // Then reduce the lodgings to the supercluster data structure
    const points = lodgingsArr.reduce((acc, lodging) => {
      if (!lodging.available) return acc; // Skip unavailable lodgings

      acc.push({
        type: "Feature" as const,
        properties: {
          cluster: false,
          lodging: lodging,
        },
        geometry: {
          type: "Point" as const,
          coordinates: [
            lodging.lodging.location.coordinates.lon,
            lodging.lodging.location.coordinates.lat,
          ],
        },
      });

      return acc;
    }, [] as Array<{ type: "Feature"; properties: { cluster: boolean; lodging: Lodging }; geometry: { type: "Point"; coordinates: [number, number] } }>);

    return points;
  }, [lodgings, isPremierCollectionEnabled]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: showClusters ? 95 : 0, maxZoom: 16 },
  });

  const getClusterPointsRecursive = useCallback(
    (clusterId: string) => {
      const getPoints: any = (clusterId: number) => {
        const clusters = supercluster?.getChildren(clusterId);
        if (!clusters || clusters.length === 0) return []; // Base case

        return clusters.flatMap((cluster) => {
          const { cluster: isCluster } = cluster.properties;

          return isCluster ? getPoints(cluster.id) : cluster;
        });
      };

      return getPoints(clusterId);
    },
    [supercluster]
  );

  const moveMapTo = (lat: number, lng: number, zoom?: number) => {
    if (!mapRef?.current) return;
    if (zoom != null) {
      mapRef.current.setZoom(zoom);
    }
    mapRef.current.panTo({
      lat: lat,
      lng: lng,
    });
  };

  const handleMarkerClick = useCallback(
    (markerId: string, lat: number, lng: number) => {
      setSelectedMarker(markerId);
      moveMapTo(lat, lng);
      mapRef.current.panBy(0, -100); // After moving the map, offset the Y position to account for the map card
    },
    []
  );

  const handleMarkerHover = useCallback((markerId: string) => {
    setHoveredMarker(markerId);
  }, []);

  const handleMarkerClose = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation(); // TODO: The onClick handlers overlap in the packages component which requires this fix. It should be fixed on the shared component.
      setSelectedMarker(undefined);
    },
    []
  );

  const renderMarker = useCallback(
    (point: any) => {
      const [longitude, latitude] = point.geometry.coordinates;
      const { lodging } = point.properties;

      return (
        <ListingMarker
          key={`point-${lodging?.lodging?.id}`}
          zIndex={
            [selectedMarker, hoveredMarker, listHoveredLodgingId].includes(
              lodging?.lodging?.id
            )
              ? 10
              : 0
          } // This prop is used by google-maps-react-markers during the rendering of the markers
          lat={latitude}
          lng={longitude}
          lodging={lodging}
          roomsCount={roomsCount}
          isVentureX={isVentureX}
          searchedNightCount={searchedNightCount || 0}
          rewardsKey={accountReferenceId || undefined}
          largestValueAccount={largestValueAccount}
          isSelected={lodging?.lodging?.id === selectedMarker}
          forceHoverState={lodging?.lodging?.id === listHoveredLodgingId}
          onMarkerHover={handleMarkerHover}
          onMarkerClick={handleMarkerClick}
          onMarkerClose={handleMarkerClose}
          onMarkerDetailsClick={() => {
            openHotelDetailsPage(
              lodging,
              expState,
              mapBounds,
              searchLocationResult,
              roomsCount,
              hotelQueryParams,
              sortOrder
            );
          }}
        />
      );
    },
    [
      selectedMarker,
      hoveredMarker,
      listHoveredLodgingId,
      roomsCount,
      expState,
      handleMarkerHover,
      handleMarkerClick,
      handleMarkerClose,
    ]
  );

  const handleClusterOnClick = useCallback(
    (clusterId: number, lat: number, lng: number) => {
      let zoom =
        supercluster?.getClusterExpansionZoom(clusterId) || DEFAULT_ZOOM_LEVEL;
      moveMapTo(lat, lng, zoom);
    },
    [supercluster]
  );

  const renderCluster = useCallback(
    (cluster: any) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { point_count: pointCount } = cluster.properties;
      return (
        <ClusterMarker
          key={`cluster-${cluster.id}`}
          lat={latitude}
          lng={longitude}
          pointCount={pointCount}
          clusterId={cluster.id}
          onClick={handleClusterOnClick}
        />
      );
    },
    [supercluster]
  );

  const renderMarkers = useMemo(() => {
    return clusters
      .flatMap((cluster: any) => {
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // Don't show any clusters
        if (!showClusters) {
          if (isCluster) {
            const points = getClusterPointsRecursive(cluster.id) || [];
            return points?.map((point: any) => {
              return renderMarker(point);
            });
          } else {
            return renderMarker(cluster);
          }
        }

        // Show clusters (minimum 4 markers for a cluster)
        if (showClusters) {
          if (isCluster && pointCount < 4) {
            const points = getClusterPointsRecursive(cluster.id) || [];
            return points?.map((point: any) => {
              return renderMarker(point);
            });
          } else if (isCluster) {
            return renderCluster(cluster);
          } else {
            return renderMarker(cluster);
          }
        }
      })
      .filter(Boolean);
  }, [
    clusters,
    showClusters,
    getClusterPointsRecursive,
    renderCluster,
    renderMarker,
  ]);

  const handleMapOnChange = ({
    zoom,
    bounds,
  }: {
    zoom: number;
    bounds: LatLngBounds;
  }) => {
    setZoom(zoom);
    if (bounds) {
      setBounds([
        bounds.getSouthWest().lng(),
        bounds.getSouthWest().lat(),
        bounds.getNorthEast().lng(),
        bounds.getNorthEast().lat(),
      ]);
    }
  };

  const handleSearchThisArea = () => {
    if (mapRef?.current) {
      setCenterCoordsLoaded(false);
      setSearchedMapBound({
        northEast: {
          lat: mapRef?.current.getBounds().getNorthEast().lat(),
          lon: mapRef?.current.getBounds().getNorthEast().lng(),
        },
        southWest: {
          lat: mapRef?.current.getBounds().getSouthWest().lat(),
          lon: mapRef?.current.getBounds().getSouthWest().lng(),
        },
        LocationDescriptor: LocationDescriptorEnum.BoundingBox,
      });

      fetchInitialHotelAvailability({
        history: history,
        searchFromMap: true,
        searchHotelsNear: false,
        includeHomes: true,
      });
    }
  };

  return (
    <Box
      className="lodging-availability-cluster-map"
      width="100%"
      height="100%"
      position="relative"
    >
      <Skeleton
        variant="rect"
        height="100%"
        animation="wave"
        style={{
          display: initialMapLoaded ? "none" : "block",
        }}
      />

      {debugMode && initialMapLoaded && (
        <>
          {fetchingMoreListings && (
            <Box
              position="absolute"
              top="10px"
              left="10px"
              display="flex"
              alignItems="center"
              padding="5px"
              zIndex="10"
              style={{
                backgroundColor: "white",
                gap: "5px",
                borderRadius: "5px",
                border: "1px solid #026597",
              }}
            >
              <Typography
                style={{
                  color: "#026597",
                  fontWeight: "600",
                }}
              >
                Loading more stays!
              </Typography>
              <CircularProgress size="20px" />
            </Box>
          )}
          <Box
            position="absolute"
            top="10px"
            right="10px"
            zIndex={1}
            display="flex"
            style={{ gap: "10px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              padding="5px"
              style={{
                backgroundColor: "white",
                gap: "5px",
                borderRadius: "5px",
                border: "1px solid #026597",
              }}
            >
              <Typography
                style={{
                  color: "#026597",
                  fontWeight: "600",
                }}
                onClick={() => setShowClusters((prev) => !prev)}
              >
                Show clusters
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              padding="5px"
              style={{
                backgroundColor: "white",
                gap: "5px",
                borderRadius: "5px",
                border: "1px solid #026597",
              }}
            >
              <Typography
                style={{
                  color: "#026597",
                  fontWeight: "600",
                }}
              >
                Zoom: {zoom}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {initialMapLoaded && (
        <Box
          position="absolute"
          bottom="7%"
          left="calc(50% - (150px / 2))"
          zIndex={1}
          display="flex"
          alignItems="center"
          padding="5px"
        >
          <ActionButton
            defaultStyle="h4r-primary"
            onClick={handleSearchThisArea}
            message={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ gap: "5px" }}
              >
                <Icon name={IconName.MagnifyingGlass} className="white-icon" />
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  Search this area
                </Typography>
              </Box>
            }
          />
        </Box>
      )}

      <GoogleMap
        apiKey={config.googleMapsApiKey}
        options={{
          clickableIcons: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: MAP_STYLE,
          gestureHandling: "greedy",
        }}
        onGoogleApiLoaded={onGoogleApiLoaded}
        errorContent={null}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM_LEVEL}
        onChange={handleMapOnChange}
        // externalApiParams={{ language: getLang() }} // TODO: Not implemented
        // yesIWantToUseGoogleMapApiInternals // TODO: Not implemented (unsure what it's for)
      >
        {renderMarkers}
      </GoogleMap>
    </Box>
  );
};

interface IClusterMarker {
  lat: number;
  lng: number;
  pointCount: number;
  clusterId: string;
  onClick: (clusterId: number, lat: number, lng: number) => void;
}
export const ClusterMarker = (props: IClusterMarker) => {
  const { lat, lng, pointCount, clusterId, onClick } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="75px"
      height="75px"
      style={{
        backgroundColor: "rgba(2, 101, 151, 0.2)",
        borderRadius: "125px",
      }}
      onClick={() => onClick(Number(clusterId), lat, lng)}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50px"
        height="50px"
        style={{
          backgroundColor: "white",
          borderRadius: "100px",
          border: "1px solid #026597",
        }}
      >
        <Typography
          style={{
            color: "#026597",
            fontWeight: "600",
          }}
        >
          {pointCount}
        </Typography>
      </Box>
    </Box>
  );
};

interface IListingMarker {
  lat: number;
  lng: number;
  zIndex: number; // **Important** This prop is used by google-maps-react-markers during the rendering of the markers
  lodging: Lodging;
  roomsCount: number;
  isVentureX: boolean;
  searchedNightCount: number;
  largestValueAccount: RewardsAccount;
  rewardsKey: string | undefined;
  isSelected: boolean;
  forceHoverState?: boolean;
  onMarkerHover?: (markerId: string) => void;
  onMarkerClick?: (markerId: string, lat: number, lng: number) => void;
  onMarkerClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onMarkerDetailsClick: () => void;
}

export const ListingMarker = ({
  lat,
  lng,
  lodging,
  roomsCount,
  isVentureX,
  searchedNightCount,
  rewardsKey,
  largestValueAccount,
  isSelected,
  forceHoverState,
  onMarkerHover,
  onMarkerClick,
  onMarkerClose,
  onMarkerDetailsClick,
}: IListingMarker) => {
  const { price } = lodging;
  const { policies } = useContext(ClientContext);

  const expState = useExperiments();

  // Experiments
  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );

  const isLodgingPromotionsExperiment =
    lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE;

  const showPCStyling =
    isPremierCollectionEnabled &&
    lodging.lodgingCollection === LodgingCollectionEnum.Premier;

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const showLCStyling =
    (isLCForPremiumCardHoldersEnabled || isLCForNonPremiumCardHoldersEnabled) &&
    lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle;

  const isHome = Boolean(lodging?.homesListingDetails);

  const showOnSaleIcon =
    isLodgingPromotionsExperiment &&
    lodging.bestPromotionThisLodging?.promotionType ===
      LodgingPromotionType.DealOfTheDay &&
    lodging.lodging.tripAdvisorReviews &&
    lodging.lodging.tripAdvisorReviews.overallScore * 5 >= 3.5 &&
    lodging.price?.discountHunting &&
    lodging.price?.discountHunting?.savingsAmount.fiat.value >= 5 &&
    (isVentureX
      ? lodging.lodging.starRating === HotelStarRatingEnum.Four ||
        lodging.lodging.starRating === HotelStarRatingEnum.Five
      : true);

  const showPolicyBanner = useShowPolicyBanner(policies);

  const isCorporate = "corporateTravel" in lodging.lodging;
  const isInPolicy =
    isCorporate &&
    (lodging as CorpLodging).lodging.corporateTravel.policyCompliance
      .isInPolicy;

  const hasCorporate = isCorporate && lodging.hasCorporateRates;

  // Marker logic
  const markerPrice = getMarkerPrice(roomsCount, price);

  if (!markerPrice) {
    return null;
  }

  const handleMarkerClick = () => {
    if (onMarkerClick) {
      onMarkerClick(lodging?.lodging?.id, lat, lng);
    }
  };

  const handleMarkerHover = () => {
    if (onMarkerHover) {
      onMarkerHover(lodging?.lodging?.id);
    }
  };

  const renderIcon = () => {
    if (isHome) {
      return (
        <Icon className="icon-fill icon-hover-fill" name={IconName.HomeIcon} />
      );
    } else if (showOnSaleIcon) {
      return (
        <Icon
          className="icon-fill icon-hover-fill"
          name={IconName.PreferredHotelIcon}
        />
      );
    } else if (showPolicyBanner && isInPolicy) {
      return (
        <Icon
          width="20px"
          height="20px"
          className="icon-hover-stroke"
          name={IconName.CheckMarkGreen}
        />
      );
    } else if (hasCorporate) {
      return (
        <Icon width="15px" height="15px" name={IconName.StarCircledIcon} />
      );
    } else {
      return null;
    }
  };

  const renderPrice = () => {
    return (
      <Typography
        color="inherit"
        style={{
          fontWeight: "600",
        }}
      >
        {getTotalPriceText({
          price: markerPrice,
          priceFormatter: genericPriceFormatter,
        })}
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position={"absolute"}
      style={{
        transform: "translate(-50%, -100%)",
      }}
      onMouseEnter={handleMarkerHover}
    >
      {isSelected && (
        // TODO: This wrapper is duplicated by all clients and should be part of the HotelAvailabilityMapTooltip component.
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="9px"
          marginBottom="5px"
          boxShadow="0px 0px 10px 2px var(--backdrop-shadow)"
          style={{
            backgroundColor: "var(--white)",
          }}
        >
          <HotelAvailabilityMapTooltip
            lodging={lodging}
            onCloseClick={onMarkerClose}
            onClickHotelDetails={onMarkerDetailsClick}
            nightCount={searchedNightCount}
            rewardsKey={rewardsKey}
            isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
            isVentureX={isVentureX}
            largestValueAccount={largestValueAccount}
            onViewCarouselImage={() => {}}
            policyLimit={policies?.hotels.policies[0].maxPricePerNight}
            tenant={config.TENANT}
            searchedRoomsCount={isCorporate ? roomsCount : undefined}
            onViewCorpCompareBar={onOpenCompareBarTooltip(
              ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_MAP
            )}
            showPolicyBanner={showPolicyBanner}
          />
        </Box>
      )}

      {showPCStyling || showLCStyling ? (
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={clsx("premium-map-marker", {
            selected: isSelected || forceHoverState,
          })}
        >
          <Icon
            name={IconName.PremierCollectionPricePin}
            className="icon-stroke icon-hover-fill "
          />
          <Box position="absolute" top="2px">
            {renderPrice()}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={clsx("default-map-marker", {
            selected: isSelected || forceHoverState,
            "green-overrides": isSignificantSavings(
              lodging.price,
              lodging.bestPromotionThisLodging
            ),
            "grey-overrides": isCorporate && !isInPolicy,
          })}
          onClick={handleMarkerClick}
        >
          {renderIcon()}
          {renderPrice()}
        </Box>
      )}
    </Box>
  );
};
