import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityClusterMap } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getHotelAvailabilitySearchLocation,
  getHotelAvailabilityCallState,
  getFilteredHotelAvailabilityLodgings,
  getMapBound,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilitySortOption,
  getHotelAvailabilityLodgingIdHovered,
  getHotelAvailabilityLodgingIdInFocus,
  getSearchedNightCount,
} from "../../reducer/selectors";
import { actions } from "../../actions";

import { getHotelQueryParams } from "../../reducer";
import { getRoomsCount } from "../../../search/reducer";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  /**
   * List of lodgings that are returned from the search query (continually update)
   */
  const visibleLodgings = getFilteredHotelAvailabilityLodgings(state);

  /**
   * This returns a lodging location from the results (not sure how the lodging is determined)
   */
  const searchLocation = getHotelAvailabilitySearchLocation(state);

  /**
   * The state of the call loop to fetch more lodgings.
   * 1. InitialSearchCallInProcess,
   * 2. InitialSearchCallSuccess,
   * 3. FollowUpSearchCallInProcess,
   * 4. FollowUpSearchCallSuccess,
   * (3-4 loop until 5)
   * 5. Complete
   */
  const callState = getHotelAvailabilityCallState(state);

  /**
   * The lodging being hovered from the list.
   */
  const listHoveredLodgingId = getHotelAvailabilityLodgingIdHovered(state);

  /**
   * The lodging selected from the list.
   */
  const listSelectedLodgingId = getHotelAvailabilityLodgingIdInFocus(state);

  /**
   * Only used for rendering the marker
   */
  const isVentureX = !!getRewardsAccounts(state).find((acc) =>
    acc.productDisplayName.includes("Venture X")
  );
  const searchedNightCount = getSearchedNightCount(state);
  const accountReferenceId =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state);
  const largestValueAccount = getRewardsAccountWithLargestEarnForBanner(state);

  // const nightCount = getSearchedNightCount(state);
  // const viewHotelsNearLocationCategories =
  //   getViewHotelsNearLocationCategories(state);
  // const viewHotelsNearLocation = getViewHotelsNearLocation(state);
  // const viewHotelsNearAvailabilityProperties =
  //   getViewHotelsNearAvailabilityProperties(state);
  // const searchCentroids = getHotelAvailabilityCentroids(state); // Null on happy path load

  // Only used to determine how to contruct the path to open a Hotel/VR.
  const mapBounds = getMapBound(state); // Null on happy path load
  const hotelQueryParams = getHotelQueryParams(state);
  const searchLocationResult = getHotelAvailabilitySearchLocationResult(state);
  const sortOrder = getHotelAvailabilitySortOption(state);
  const roomsCount = getRoomsCount(state);

  return {
    lodgings: visibleLodgings,
    searchLocation,
    callState,
    listHoveredLodgingId,
    listSelectedLodgingId,
    isVentureX,
    searchedNightCount,
    accountReferenceId,
    largestValueAccount,
    // lodgingIdHovered,
    // searchedMapBound,
    // nightCount,
    // viewHotelsNearLocationCategories,
    // viewHotelsNearLocation,
    // viewHotelsNearAvailabilityProperties,
    // searchCentroids, // Undefined when used. Seems useless

    mapBounds,
    hotelQueryParams,
    searchLocationResult,
    sortOrder,
    roomsCount,
  };
};

const mapDispatchToProps = {
  fetchInitialHotelAvailability: actions.fetchInitialHotelAvailability,
  setSearchedMapBound: actions.setMapBound, // We set the map bounds of the current view as the bounds are required during the fetchInitialHotelAvailability call

  // setLodgingIdInFocus: actions.setLodgingIdInFocus,
  // fetchViewHotelsNearLocationCategories:
  //   actions.fetchViewHotelsNearLocationCategories,
  // setViewHotelsNearLocation: actions.setViewHotelsNearLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityClusterMapConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityClusterMap = connector(
  withRouter(AvailabilityClusterMap)
);
